<template>
  <div class="certificates">
    <div class="certificates__container _container">
      <BreadCrumbs :items="[[$t('main'), '/'], [$t('certific')]]" />
      <h1 class="certificates__title main-title">{{ $t("certific") }}</h1>
      <div class="certificates__body">
        <div class="certificates__items">
          <div class="certificates__item" v-for="certificate in certificates">
            <img :src="cdn + certificate.image" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import BreadCrumbs from "../components/UI/BreadCrumbs.vue";

export default {
  data() {
    return {
      certificates: [],
    };
  },
  computed: {
    ...mapState(["cdn"]),
  },
  created() {
    axios
      .get(`V1/certificates`)
      .then((response) => {
        this.certificates = response.data.data;
      })
      .catch((error) => {
      });
  },
  components: { BreadCrumbs },
};
</script>

<style lang="scss">
.certificates {
  padding: 50px 0 135px 0;
  @media (max-width: $mobileSmall + px) {
    padding: 32px 0 142px 0;
  }

  &__container {
    @media (max-width: $mobile + px) {
      padding: 0 0 0 16px;
    }
  }

  &__title {
    margin: 0 0 38px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 35px 0;
    }
  }

  &__items {
    display: flex;
    align-items: flex-start;
    gap: 15px 26px;
    @media (min-width: $mobile + px) {
      flex-wrap: wrap;
    }
    @media (max-width: $mobile + px) {
      width: 100%;
      overflow-x: auto;
      padding: 0 16px 0 0;
      //scrollbar-width: none;
      //-ms-overflow-style: none;
      //&::-webkit-scrollbar {
      //  width: 0;
      //  height: 0;
      //}
    }
  }
  &__item {
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
    flex: 0 0 185px;
    height: 270px;
    img {
      max-height: 100%;
      max-width: 100%;
      height: 100%;
      width: 100%;
    }
  }
}
</style>